<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      loading: true,
      messagelist: [],
      page: 1,
      userinfo: [],
      limit: 20,
      messagetotal: 0,
      notifysetting: {}
    };
  },
  mounted() {
    this.title = this.$t("menuitems.clouds.notify.text");
    this.items = [
      {
        text: this.$t("menuitems.clouds.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.clouds.notify.text"),
        active: true,
      },
    ];
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    //   this.page.title=this.$t('global.notice.type.notify')
    this.getnotifysetting();
  },
  methods: {
    getnotifysetting() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getnotifysetting",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.notifysetting = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    savenotifysetting() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "savenotifysetting",
            data: that.notifysetting
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.getnotifysetting();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <el-form ref="form" :model="notifysetting" label-width="80px">
              <el-form-item label="启用状态">
                <el-switch :active-value="1" :inactive-value="0" v-model="notifysetting.status"></el-switch>
              </el-form-item>
              <el-form-item label="群组ID">
                <el-input v-model="notifysetting.id"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="savenotifysetting">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>